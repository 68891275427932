.work-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  text-align: left;
  padding: 35px 0 0;
}

.work-info__view-box {
  display: flex;
  justify-content: space-between;
}

.work-info__img-box {
  $self: &;
  position: relative;
  text-align: right;
  width: 90px;
  aspect-ratio: 16/9;
  border-radius: 5px;
  overflow: hidden;
  &.s-register,
  &:hover {
    .work-info__img-btn {
      opacity: 0.7;
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.work-info__img-btn {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  opacity: 0;
  transition: opacity 0.2s ease;
  svg {
    font-size: 20px;
    color: #000;
  }
}

.work-info-etc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0 0;
  padding: 5px 0 0;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  &__item {
    &:nth-child(1) {
      flex: 1 0 auto;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      min-width: 35px;
      max-width: 70px;
    }
    &:nth-child(2) {
      margin: 0 0 0 5px;
      width: 200px;
    }
    &:nth-child(3) {
      text-align: right;
    }
  }
  &__txt {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.7);
  }
  &__range-input {
    width: 50px;
    &::-webkit-slider-runnable-track {
      background-color: rgba(255, 255, 255, 0.2);
      height: 4px;
      border-radius: 10px;
    }
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      background: #aaa;
      cursor: pointer;
      height: 12px;
      width: 12px;
      margin-top: -4px;
      border-radius: 100%;
    }
  }
}
