.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  &__content {
    width: 300px;
  }
}

$mainLogin: '.main-login';
#{$mainLogin} {
  margin-top: 30px;
  &__msg-google {
    font-weight: 500;
    font-size: 20px;
  }
  &__msg-domain {
    display: block;
    margin-top: 5px;
    font-size: 14px;
    opacity: 0.5;
  }
  &__google {
    margin-top: 20px;
  }
}
