@import './base/reset.scss';

::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 30px;
}
::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

body {
  color: #fff;
}

html,
body {
  height: 100%;
}

#root {
  height: 100%;
}

.l-app {
  height: 100%;
}

.l-container {
  position: relative;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  text-align: center;
  background-color: #000;
  border-radius: 10px;
  overflow: hidden;
  &.p-main {
    justify-content: center;
    background-color: rgba(0, 0, 0, calc(var(--bg-transparency, 10) / 10));
  }
}

.c-logo-set {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &__logo {
    font-size: 60px;
  }
  &__txt {
    margin: -14px 0 0 -5px;
    font-size: 30px;
  }
  &__sub-txt {
    position: absolute;
    bottom: 10px;
    right: 0;
    font-size: 12px;
    opacity: 0.5;
  }
}

.c-list {
  &__item {
    display: flex;
    align-items: center;
    margin: 10px 0 0;
    &:first-of-type {
      margin-top: 0;
    }
  }
  &__emoji {
    margin: 2px 5px 0 0;
    font-size: 20px;
  }
  &__txt {
    font-size: 14px;
  }
}

.c-loader {
  position: fixed;
  top: 15px;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.google-login {
  width: 100%;
}
