.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  &__info {
    
  }
  &__version {
    display: block;
    margin: 15px 0 0;
    font-size: 12px;
  }

  &__created {
    margin: 15px 0 0;
    &-tit {
      display: block;
      font-weight: 700;
      font-size: 14px;
    }
    &-txt {
      margin: 5px 0 0;
      display: block;
      font-size: 14px;
      line-height: 1.4;
    }
    &-sub-txt {
      // display: block;
      font-size: 12px;
      opacity: 0.6;
    }
  }

  &__copyright {
    margin: 15px 0 0;
    font-size: 12px;
  }
}

:global .p-about {
  border-radius: 0;
}
