.top-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-user-select: none;
  -webkit-app-region: drag;
  padding: 15px;
  cursor: move;
}

.top-bar__tit-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 0 0;
  width: 100%;
}
.top-bar__tit-icon {
  
}
.top-bar__tit-txt {
  margin: -2px 0 0 2px;
  font-weight: 500;
  font-size: 12px;
}

.top-bar__btn-box {
  position: absolute;
  top: 15px;
  &--left {
    left: 15px;
  }
  &--right {
    right: 15px;
  }
}

.top-bar__btn {
  margin: 0 0 0 3px;
  padding: 2px;
  font-size: 14px;
  -webkit-app-region: no-drag;
  cursor: pointer;
  &:nth-of-type {
    margin-left: 0;
  }
  &[disabled] {
    opacity: 0.2;
    pointer-events: none;
  }
  &--noti {
    position: relative;
    &:global.s-active {
      &::before {
        content: "";
        display: block;
        position: absolute;
        top: -1px;
        right: -2px;
        width: 5px;
        height: 5px;
        border-radius: 100%;
        background-color: red;
      }
    }
  }
}
