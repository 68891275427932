.noti {
  padding: 45px 0 0;
  width: 100%;
  height: 100%;
  text-align: left;
  &__top-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 15px 15px 12px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    background-color: #000;
  }
  &__tit {
    font-size: 14px;
  }
  // &__close-btn {}
  &__box {
    height: 100%;
    overflow-y: scroll;
    :global .c-list__item {
      align-items: flex-start;
    }
    :global .c-list__txt {
      line-height: 1.4;
    }
  }
}
